<ng-container *ngIf="player.cued()">
    <player-seekbar></player-seekbar>

    <div class="controls">
        <button class="no-style maximize" (click)="overlay.maximize()"><mat-icon svgIcon="keyboard-arrow-up"></mat-icon></button>
        <div class="track-info">
            <div class="track-name">{{player.getCuedTrack().name}}</div>
            <artists-links-list class="artist-name" [track]="player.getCuedTrack()"></artists-links-list>
        </div>
        <main-playback-buttons></main-playback-buttons>
    </div>
</ng-container>

<custom-menu position="mobile-bottom" class="nav"></custom-menu>