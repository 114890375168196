<div class="icon">
    <img [src]="imgSrc" alt="">
</div>
<div class="text">
    <div class="main">
        <ng-content select="[primary-text]"></ng-content>
    </div>
    <div class="secondary">
        <ng-content select="[secondary-text]"></ng-content>
    </div>
</div>
